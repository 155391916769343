export const toggleStateArr = [
    "standing",
    "walking",
    "running",
    "sitting",
    "bentOver",
    "moving",
    "resting",
    "motionless",
];
